import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import LogoValeti from '../../assets/images/logo.jpg';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://valeti.com/">
        Valeti -
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff4e00',
    color: '#fff'
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: 150
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2),
    },
    color: theme.palette.text.white,
  }
}));


export default function Success() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img alt='Logo Valeti' className={classes.logo} src={LogoValeti} />
        <Typography component="h1" variant="h5">
          Resetar senha Valeti App
        </Typography>
        <br /><br />
        <Typography component="h1" variant="h4">
          Pronto! Aproveite ao máximo as funcionalidades e ferramentas do Painel de Controle Valeti.
        </Typography>
        <br />
        Clique <a href="https://portal.valeti.com">aqui</a> para realizar o login!
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}