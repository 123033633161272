import React, { useState } from "react";
import ReactLoading from "react-loading";
import { useQueryParams } from "hookrouter";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import history from "../../services/history";
import LogoValeti from "../../assets/images/logo.jpg";
import api from "../../services/api";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://valeti.com/">
        Valeti -
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ff4e00",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "grey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: "#ff4e00",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff4e00",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#ff4e00",
    color: "#fff",
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: 150,
  },
  textField: {
    width: "100%",
    "& + & ": {
      marginTop: theme.spacing(2),
    },
    color: theme.palette.text.white,
  },
}));

export default function SignIn({ match }) {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [queryParams] = useQueryParams();
  let disabled = true;

  const regexNumber = /[0-9]/;
  const regexLetter1 = /[a-z]/;
  const regexLetter2 = /[A-Z]/;

  if (
    password !== "" &&
    password === confirmPassword &&
    password.length >= 8 &&
    regexNumber.test(password) &&
    (regexLetter1.test(password) || regexLetter2.test(password))
  ) {
    disabled = false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dados = {
      token: queryParams.token,
      password: password,
    };

    api
      .post("/portalWeb/resetPassword", dados)
      .then((res) => {
        setLoading(false);
        history.push("/sucesso");
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        setPassword("");
        setConfirmPassword("");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img alt="Logo Valeti" className={classes.logo} src={LogoValeti} />
        <Typography component="h1" variant="h5">
          Resetar senha Painel de Controle Valeti
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <CssTextField
            className={classes.margin}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Digite sua nova senha"
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <CssTextField
            className={classes.margin}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirme sua senha"
            name="confirmPassword"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <small>
            Sua senha precisa conter no mínimo 8 dígitos, 1 número e 1 letra
          </small>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            disabled={disabled}
          >
            {loading && (
              <ReactLoading
                type="spin"
                color="#FF4E00"
                height={20}
                width={20}
              />
            )}
            Confirmar
          </Button>
          {error && (
            <Typography
              component="h6"
              variant="h6"
              style={{ color: "red", fontWeight: "900", fontSize: "12" }}
            >
              Erro ao trocar senha. Por favor, tente novamente!
            </Typography>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
